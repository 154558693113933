<template>
  <main class="animated fadeInLeft">
    <vue-headful
        title="Lean2Grow | Consultoria e Serviços"
        description="Ajudamos líderes e empresas a elevarem a sua produtividade"
    />
    <!-- slider Area Start-->
    <div class="slider-area ">
      <!-- Mobile Menu -->
      <div class="single-slider slider-height2 d-flex align-items-center"
           :style="{'background-image': 'url(' + require('../../assets/img/hero/about_hero.jpg') + ')'}">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap text-center">
                <h2>Portfólio</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider Area End-->
    <portfolio-content></portfolio-content>
  </main>
</template>

<script>
import PortfolioContent from "./PortfolioContent";

export default {
  name: "Portfolio",
  metaInfo: {
    title: 'Portfólio',
    meta: [
      {
        name: 'description',
        content: 'Na Lean2Grow, somos particularmente competentes nas áreas de Gestão Estratégica de ' +
            'Capital Humano, Formação, Desenvolvimento Organizacional,...'
      }
    ]
  },
  components: {PortfolioContent}
}
</script>

<style scoped>
</style>
