<template>
    <!-- Portfolio Start -->
    <div class="completed-cases section-padding3">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_3.jpg" alt="">
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_4.jpg" alt="">
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_5.jpg" alt="">
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_1.jpg" alt="Lean2grow team">
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_2.jpg" alt="Lean2Grow team">
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/Samuel_Maputso_image.jpg" alt="Samuel Maputso image">
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/Samuel_Maputso_presenting.jpg" alt="Samuel Maputso presenting">
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_5.jpg" alt="">
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_6.jpg" alt="">
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4">
                    <div class="single-cases-img  size mb-30">
                        <img src="../../assets/img/team/lean2grow_team_7.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Portfolio end -->
</template>

<script>
    export default {
        name: "PortfolioContent"
    }
</script>

<style scoped>

</style>
